export default [
  {
    path: '/privacy/:type',
    name: 'Privacy',
    meta: { title: '用户协议' },
    component: () => import('@/views/privacy')
  },
  {
    path: '/notice/:id',
    meta: { title: '通知详情' },
    component: () => import('@/views/privacy/notice')
  },
  {
    path: '/bulletin-list',
    meta: { title: '公告' },
    component: () => import('@/views/privacy/bulletinList')
  },
  {
    path: '/bulletin/:id',
    name: 'Bulletin',
    meta: { title: '系统公告' },
    component: () => import('@/views/privacy/bulletin')
  },
  {
    path: '/activityfaq/:id',
    meta: { title: '常见问题详情' },
    component: () => import('@/views/privacy/activityFaq')
  },
  {
    path: '/activityintroduce/:id',
    meta: { title: '活动介绍' },
    component: () => import('@/views/privacy/activityIntroduce')
  },
  {
    path: '/activityApplyDetail',
    meta: { title: '活动申报详情' },
    component: () => import('@/views/privacy/activityApplyDetail')
  },
  {
    path: '/courseproblem/:id',
    meta: { title: '课程常见问题' },
    component: () => import('@/views/privacy/courseProblem')
  },
  {
    path: '/help/:id',
    meta: { title: '帮助中心' },
    component: () => import('@/views/privacy/help')
  },
  {
    path: '/convention',
    meta: { title: '社区公约' },
    component: () => import('@/views/privacy/convention')
  }
]